<div class="title-container" [ngClass]="titleTheme">
  <div class="title">
    @if (titleTheme === dialogThemes.WARN) {
      <app-svg-sprite icon="warning-fill" classes="tw-w-32-px tw-mr-8-px tw-fill-gamboge tw-p-8-px tw-bg-old-lace tw-rounded tw-align-middle"></app-svg-sprite>
    }
    @if (titleTheme === dialogThemes.DANGER) {
      <app-svg-sprite icon="danger-fill" classes="tw-w-20-px tw-mr-8-px tw-align-text-bottom tw-fill-fire-engine-red"></app-svg-sprite>
    }
    @if (titleTheme === dialogThemes.INFO) {
      <app-svg-sprite icon="info-circle-fill" classes="tw-w-20-px tw-mr-8-px tw-fill-cornflower-blue"></app-svg-sprite>
    }
    @if (titleTheme === dialogThemes.SUCCESS) {
      <app-svg-sprite icon="check" classes="tw-w-18-px tw-mr-8-px tw-fill-white tw-bg-chateau-green tw-rounded-1/2 tw-p-4-px"></app-svg-sprite>
    }
    {{ title }}
    <span class="cursor-pointer tw-rounded-border-radius-2xs
    tw-w-30-px tw-flex tw-justify-center tw-items-center hover:tw-bg-grey-tertiary" (click)="close('close')" title="close">
      <app-svg-sprite icon="cross" classes="tw-w-16-px tw-h-16-px tw-m-spacing-4 tw-fill-mischka"></app-svg-sprite>
    </span>
  </div>
</div>