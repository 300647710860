import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[uiAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  @Input() autofocus = true;

  @Input() timeout = 0;

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.autofocus) {
      return;
    }

    if (this.timeout) {
      setTimeout(() => {
        this.element.nativeElement.focus();
      }, this.timeout);
    } else {
      this.element.nativeElement.focus();
    }
  }
}
