import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { TrackingService } from '../../core/services/tracking.service';
import { ResetPasswordQueryParams } from './reset-password-query-params.interface';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';
import { TargetAppConfig } from '../../core/models/target-app-config.model';
import { SignInQueryParams } from '../sign-in/sign-in-query-params.interface';
import { ButtonType } from '@fyle/model-shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormControl;

  hasRequestedResetPasswordLink: boolean;

  isResendAPI: boolean;

  canChangeContent: boolean;

  showLoaderOnButton: boolean;

  buttonType = ButtonType;

  tmpPasswordExpired = signal(false);

  private queryParams: ResetPasswordQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private routerAuthService: RouterAuthService,
    private targetAppConfigService: TargetAppConfigService,
  ) {
    this.resetPasswordForm = new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
    ]);
  }

  sendResetLink() {
    this.isResendAPI = false;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.showLoaderOnButton = true;

    this.routerAuthService
      .sendResetPassword(this.resetPasswordForm.value)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 422) {
            this.router.navigate(['disabled']);
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.resendCircleTimer();
          this.hasRequestedResetPasswordLink = true;
        }),
      )
      .subscribe((resp) => this.trackingService.resetPassword());
  }

  resendCircleTimer() {
    if (this.hasRequestedResetPasswordLink) {
      setTimeout(() => {
        this.isResendAPI = true;
        this.canChangeContent = true;
      }, 300);
    } else {
      this.isResendAPI = true;
    }
  }

  goToSignIn() {
    const signinParams: SignInQueryParams = {
      email: this.resetPasswordForm.value,
    };
    this.router.navigate(['signin'], { queryParams: signinParams });
  }

  ngOnInit() {
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    this.queryParams = this.activatedRoute.snapshot.queryParams as ResetPasswordQueryParams;
    if (this.queryParams.email) {
      this.resetPasswordForm.setValue(this.queryParams.email);
    }
    if (typeof this.queryParams.tmp_pwd_expired !== 'undefined') {
      const booleanValue = this.queryParams.tmp_pwd_expired === 'true';
      this.tmpPasswordExpired.set(booleanValue);
    }
  }
}
