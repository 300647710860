import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PasswordStrength } from './password-rules.interface';

export class PasswordRulesValidator {
  constructor() {}

  private static updateErrorObject(errorObj: PasswordStrength, isValid: boolean, errorName: keyof PasswordStrength) {
    if (!isValid) {
      errorObj[errorName] = !isValid;
    }
  }

  static checkPasswordStrength(control: AbstractControl): ValidationErrors {
    const value = control.value;
    const hasCharacterLimit = value?.length >= 12 && value?.length <= 32;
    const hasUppercaseCharacter = /[A-Z]/.test(value);
    const hasLowercaseCharacter = /[a-z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialCharacter = /[!#$%&()*+:;<=>?@^{|}~\-]/.test(value);

    const errorObj = {} as PasswordStrength;
    PasswordRulesValidator.updateErrorObject(errorObj, hasCharacterLimit, 'hasCharacterLimit');
    PasswordRulesValidator.updateErrorObject(errorObj, hasUppercaseCharacter, 'hasUppercaseCharacter');
    PasswordRulesValidator.updateErrorObject(errorObj, hasLowercaseCharacter, 'hasLowercaseCharacter');
    PasswordRulesValidator.updateErrorObject(errorObj, hasDigit, 'hasDigit');
    PasswordRulesValidator.updateErrorObject(errorObj, hasSpecialCharacter, 'hasSpecialCharacter');

    return errorObj;
  }
}
