<!-- 'fdl-styles' class contains all the styles we need for styling p-dropdown and p-dropdown-items,
applying the class to root element i.e. p-dropdown and passing it in panelStyleClass for applying styles on dropdown items -->

<p-dropdown
  #pDropdown
  name="dropdown"
  [formControl]="dropdownControl"
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [filter]="showSearch"
  [filterBy]="searchByKeys"
  [resetFilterOnHide]="true"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [lazy]="lazy"
  (onChange)="onChange($event)"
  (onBlur)="onBlur()"
  (onShow)="onPanelShow()"
  (onHide)="onPanelHide()"
  (onLazyLoad)="onLazyLoad($event)"
  [virtualScrollOptions]="{
    autoSize: false,
    scrollHeight: '200px'
  }"
  [scrollHeight]="scrollHeight"
  autocomplete="off"
  [placeholder]="placeholder"
  [attr.placeholder]="placeholder"
  class="fdl-styles"
  styleClass="size-{{size}} {{styleClass || 'tw-w-full'}}"
  panelStyleClass="fdl-styles {{panelStyleClass || 'tw-w-full'}}"
  [optionGroupLabel]="optionGroupLabel"
  [optionGroupChildren]="optionGroupChildren"
  [optionDisabled]="optionDisabled"
  [attr.data-testid]="testId"
  [group]="group"
  [appendTo]="'body'"
  [overlayOptions]="{
    showTransitionOptions: '0.3s ease-in-out'
  }">

  <ng-template pTemplate="item" let-item>
    <!-- Custom item template passed from consumer component will be rendered here -->
    @if (itemTemplateRef) {
      <ng-template [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    } @else {
      <div class="item">
        <div>{{optionLabel ? item[optionLabel] : item}}</div>
      </div>
    }

  </ng-template>


  <ng-template pTemplate="filter" let-options="options">
    <div class="search-container">
      <input
        [(ngModel)]="searchQuery"
        autocomplete="off"
        [attr.placeholder]="dropdownSearchPlaceholder"
        class="search-input"
        type="text"
        uiAutofocus
        [timeout]="100"
        (input)="searchHandler($event, options)">
      <ui-svg-sprite [icon]="'search'" [classes]="'icon-search'"></ui-svg-sprite>
      @if(searchQuery.length > 0){
        <ui-svg-sprite icon="cross" class="tw-absolute tw-top-spacing-14 tw-right-spacing-16 tw-w-18-px tw-h-18-px tw-block tw-fill-icon-muted tw-cursor-pointer tw-animate-visible-300" (click)="clearSearchField($event, options)"></ui-svg-sprite>
      }
    </div>
  </ng-template>

  <ng-template pTemplate="dropdownicon">

    @if ((showClearIcon && !dropdownControl.value) || !showClearIcon) {
      <div>
        <ui-svg-sprite
        [icon]="'arrow-down'"
        [containerClasses]="'tw-h-18-px'"
        [classes]="'tw-h-18-px tw-w-18-px tw-fill-icon-muted ' +
        (dropdownOpen() ?
        'tw-transition-rotate tw-duration-rotate-up tw-ease-rotate-up tw-rotate-180' :
        'tw-transition-rotate tw-duration-rotate-down tw-ease-rotate-down tw-rotate-0')"></ui-svg-sprite>
      </div>
    }
    @if (showClearIcon && dropdownControl.value) {
      <div>
        <ui-svg-sprite (click)="clearFilter($event)" [icon]="'cross'" [containerClasses]="'tw-h-18-px'" [classes]="'tw-h-18-px tw-w-18-px tw-fill-icon-muted'"></ui-svg-sprite>
      </div>
    }
  </ng-template>

  <ng-template pTemplate="footer">
    @if (footerTemplateRef) {
      <ng-template [ngTemplateOutlet]="footerTemplateRef"></ng-template>
    } @else if (lazyLoadingInProgress) {
      <div class="tw-flex tw-items-center tw-justify-center tw-pb-spacing-4">
        <ui-svg-sprite [classes]="'tw-flex tw-animate-loading-spinner tw-fill-primary tw-h-24-px tw-w-24-px tw-p-spacing-2'" [containerClasses]="'tw-h-24-px tw-w-24-px'" [icon]="'loader'"></ui-svg-sprite>
      </div>
    }
  </ng-template>

  @if (selectedItemTemplateRef; as option) {
    <ng-template pTemplate="selectedItem" let-option>
      <ng-template [ngTemplateOutlet]="selectedItemTemplateRef" [ngTemplateOutletContext]="{$implicit: option}"></ng-template>
    </ng-template>
  }

  @if (emptyTemplateRef) {
    <ng-template pTemplate="empty">
      <ng-template [ngTemplateOutlet]="emptyTemplateRef"></ng-template>
    </ng-template>
  }

</p-dropdown>
