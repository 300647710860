import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { AuthService } from '../../core/services/auth.service';
import { TrackingService } from '../../core/services/tracking.service';
import { UserStorageService } from '@fyle/user-storage';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private window: Window,
    private trackingService: TrackingService,
    private authService: AuthService,
    private userStorageService: UserStorageService,
  ) {}

  ngOnInit() {
    this.trackingService.onSignOut();

    this.authService
      .logout()
      .pipe(
        finalize(() => {
          globalCacheBusterNotifier.next();

          // Avoid sharing integrations state between users
          this.userStorageService.remove('integration-latest-route');
          setTimeout(() => {
            const asset = this.activatedRoute.snapshot.queryParams.asset;
            if (asset === 'outlook' || asset === 'chrome') {
              this.window.close();
            } else {
              this.router.navigate(['signin']);
            }
          }, 1000);
        }),
      )
      .subscribe();
  }
}
