<div
  class="fdl-modal-header"
  [ngClass]="{
    'tw-p-spacing-24': type === modalHeaderTypes.NORMAL,
    'tw-p-spacing-20': type !== modalHeaderTypes.NORMAL
  }"
  >
  <div class="items-container">
    @if (type !== modalHeaderTypes.NORMAL) {
      <div
        class="icon-container"
      [ngClass]="{
        'tw-bg-bg-danger-light': [modalHeaderTypes.DANGER, modalHeaderTypes.ALERT].includes(type) ,
        'tw-bg-bg-success-light': type === modalHeaderTypes.SUCCESS,
        'tw-bg-bg-warning-lighter': type === modalHeaderTypes.WARNING
      }"
        >
        @if (type === modalHeaderTypes.DANGER) {
          <ui-svg-sprite
            class="tw-w-20-px tw-h-20-px"
            [icon]="'bin'"
            [classes]="'tw-fill-icon-danger'"
          ></ui-svg-sprite>
        }
        @if (type === modalHeaderTypes.ALERT) {
          <ui-svg-sprite
            class="tw-w-20-px tw-h-20-px"
            [icon]="'warning-outline'"
            [classes]="'tw-fill-icon-danger'"
          ></ui-svg-sprite>
        }
        @if (type === modalHeaderTypes.SUCCESS) {
          <ui-svg-sprite
            class="tw-w-20-px tw-h-20-px"
            [icon]="'check'"
            [classes]="'tw-fill-icon-success'"
          ></ui-svg-sprite>
        }
        @if (type === modalHeaderTypes.WARNING) {
          <ui-svg-sprite
            class="tw-w-20-px tw-h-20-px"
            [icon]="'warning-outline'"
            [classes]="'tw-fill-icon-warning'"
          ></ui-svg-sprite>
        }
      </div>
    } @else {
      <ng-content select="[fdlCustomModalHeader]"></ng-content>
    }

    <div class="title" [ngClass]="{'tw-gap-spacing-8 tw-items-center tw-flex': showBetaFlag}">{{ title }}
      @if (showBetaFlag) {
        <div class="beta-tag">Beta</div>
      }
    </div>

    @if (showCloseButton) {
      <div class="close close-dialog-icon-container" (click)="close()" title="close">
        <ui-svg-sprite [icon]="'cross'" [classes]="'tw-w-24-px tw-h-24-px tw-fill-modal-header-icon-color tw-m-spacing-4'" [containerClasses]="'tw-flex'"></ui-svg-sprite>
      </div>
    }
  </div>
</div>
