<div class="auth-container">

  <div class="header-container">
    <app-header [iconUrl]="targetConfig.FULL_LOGO_URL_WHITE"></app-header>
  </div>

  <div class="message-input-container">

    <div class="signup-container">

      <div class="message-container">
        <h1 class="message">
          {{signUpMessage.line1}}
          {{signUpMessage.line2}}
          <span class="img-container">
            <img src="./assets/images/thunderbolt.png" alt="Bolt" class="icon-bolt">
          </span>
        </h1>
      </div>

      <div class="content-container">
        <div class="auth-card">

          <h3 class="page-title">Get started</h3>
          <p class="description">We just need your work email address.</p>

          <form novalidate class="signup-form-container">

            <div class="tw-flex tw-flex-col">

              @if (errorState) {
                <div class="error-message-box">
                  @if (errorState === signupErrorState.UNAUTHORIZED) {
                    <span>
                      Unable to signup. If you already have a {{ targetConfig.BRAND_NAME }} account?
                      <a class="sign-in-text" href="#/signin?email={{emailForm.value}}">Try signing in?</a>
                    </span>
                  }
                  @if (errorState === signupErrorState.UNKNOWN_ERROR) {
                    <span>Something went wrong, please try again later</span>
                  }
                </div>
              }

              <div class="email-input-container">
                <input
                  [formControl]="emailForm"
                  name="email"
                  type="email"
                  class="email-input input-text"
                  placeholder="Work email"
                  [ngClass]="{'p-error': emailForm.touched && emailForm.invalid}"
                  appAutofocus>

                <div class="error-space">
                </div>

                @if (emailForm.touched && emailForm.errors) {
                  @if (emailForm.hasError('required')) {
                    <div class="error">Please enter an email</div>
                  }
                  @if (emailForm.hasError('email')) {
                    <div class="error">
                      Please enter a valid email address
                    </div>
                  }
                }

                @if (isFreeDomainEmail) {
                  <div class="error">
                    Please use your business email to signup
                  </div>
                }
              </div>

              <ui-button
                [validateForm]="true"
                [buttonType]="buttonType.PRIMARY"
                [isDisabled]="isFreeDomainEmail"
                [isLoading]="showLoaderOnButton"
                [loadingText]="'Proceeding'"
                [defaultType]="'submit'"
                [customClasses]="'tw-w-full tw-font-700 tw-mt-spacing-4'"
                (buttonClick)="basicAuthSignup()"
                [label]="'Proceed'"
                >
              </ui-button>
            </div>
          </form>
        </div>

        <p class="action-alternate">
          Already on {{ targetConfig.BRAND_NAME }}? <a class="tw-cursor-pointer sign-in" (click)="goToSignIn()">Sign in</a>
        </p>

      </div>
    </div>
  </div>
</div>
