<div class="auth-container tw-items-center tw-justify-center">
  <div
    class="tw-w-[226px] tw-h-[86px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-spacing-8 tw-rounded-lg tw-bg-white"
  >
    <app-svg-sprite
      icon="power"
      class="tw-flex tw-fill-icon-tertiary tw-w-18-px tw-h-18-px tw-justify-center"
    ></app-svg-sprite>
    <p class="tw-relative tw-m-0 tw-text-text-color-4 tw-text-16-px tw-leading-1.5 tw-text-center">
      Signing out
      <span class="tw-inline-block tw-bg-bg-secondary tw-h-2-px tw-w-2-px tw-rounded-full tw-mr-spacing-2 tw-animate-wave"></span>
      <span class="tw-inline-block tw-bg-bg-secondary tw-h-2-px tw-w-2-px tw-rounded-full tw-mr-spacing-2 tw-animate-wave [animation-delay:-900ms]"></span>
      <span class="tw-inline-block tw-bg-bg-secondary tw-h-2-px tw-w-2-px tw-rounded-full tw-animate-wave [animation-delay:-700ms]"></span>
    </p>
  </div>
</div>
