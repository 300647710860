<div class="fdl-modal-actions">
    <div class="action-container">
      @if (secondaryAction) {
        <ui-button
          [buttonType]="secondaryAction.type"
          [defaultType]="'button'"
          [label]="secondaryAction.label"
          [isLoading]="secondaryAction.loading"
          [loadingText]="secondaryAction.loadingText"
          [isDisabled]="secondaryAction.disabled"
          (buttonClick)="onSecondaryButtonClick()"
          [validateForm]="primaryAction.validateForm"
          [formToValidate]="primaryAction.formToValidate"
          [formElement]="primaryAction.formElement"
        ></ui-button>
      }
  
      <ng-content></ng-content>
  
      @if (primaryAction) {
        <div [fdlTooltip]="primaryAction.tooltipText" [tooltipDirection]="'bottom'" [tooltipDisabled]="!primaryAction.tooltipVisible">
          <ui-button
            [autofocus]="true"
            [buttonType]="primaryAction.type"
            [defaultType]="'button'"
            [label]="primaryAction.label"
            [isLoading]="primaryAction.loading"
            [loadingText]="primaryAction.loadingText"
            [isDisabled]="primaryAction.disabled"
            (buttonClick)="onPrimaryButtonClick()"
            [validateForm]="primaryAction.validateForm"
            [formToValidate]="primaryAction.formToValidate"
            [formElement]="primaryAction.formElement"
          ></ui-button>
        </div>
      }
  
    </div>
  </div>
  