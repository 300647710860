<div class="auth-container reset-password-container">
  <div class="card-container">
    <app-header></app-header>

    @if (!hasRequestedResetPasswordLink) {
      <div class="auth-card">
        <div class="content-container">
          @if (tmpPasswordExpired()) {
            <h3 class="title tw-gap-spacing-10 tw-flex tw-items-center">
              <div class="icon-container tw-p-spacing-6 tw-rounded-border-radius-2xs tw-bg-bg-warning-lighter">
                <app-svg-sprite
                  class="tw-flex" 
                  [icon]="'warning-outline'" 
                  [classes]="'tw-h-20-px tw-w-20-px tw-fill-bg-warning'">
                </app-svg-sprite> 
              </div>
              Your password has expired
            </h3>
          } @else {
            <h3 class="title"> Forgot your password? </h3>
          }
          <p class="sub-title">Enter the email associated with your Fyle account to receive a password reset link.</p>
          <form class="form-container">
            <div class="email-input-container">
              <div class="label-container">
                <div class="input-label">Work email</div>
                <span class="asterisk">*</span>
              </div>
              <div class="validation-wrapper tw-relative">
                <input
                  class="input-content input-text"
                  autocomplete="off"
                  [formControl]="resetPasswordForm"
                  name="email"
                  type="email"
                  placeholder="Enter your work email here"
                  appAutofocus
                  [ngClass]="{ 'p-error': resetPasswordForm.touched && resetPasswordForm.invalid }"
                />
                <div class="error-space"></div>
                @if (resetPasswordForm.valid) {
                  <div class="action-wrapper">
                    <span class="action-text">
                      <app-svg-sprite icon="check" class="success-icon"></app-svg-sprite>
                    </span>
                  </div>
                }
                @if (resetPasswordForm.touched && resetPasswordForm.errors) {
                  <div class="action-wrapper">
                    <span class="action-text">
                      <app-svg-sprite icon="danger-fill" class="error-icon"></app-svg-sprite>
                    </span>
                  </div>
                }
              </div>
              @if (resetPasswordForm.touched && resetPasswordForm.errors) {
                @if (resetPasswordForm.hasError('required')) {
                  <div class="error">Please enter an email address</div>
                }
                @if (resetPasswordForm.hasError('pattern')) {
                  <div class="error">Please enter a valid email address</div>
                }
              }
            </div>
            <ui-button
              [defaultType]="'submit'"
              [buttonType]="buttonType.PRIMARY"
              (buttonClick)="sendResetLink()"
              [validateForm]="true"
              [isLoading]="showLoaderOnButton"
              [label]="'Send reset link'"
              [customClasses]="'tw-w-full tw-font-700 tw-mt-spacing-4'"
              [loadingText]="'Sending'"
            >
            </ui-button>
            @if (!tmpPasswordExpired()) {
              <div class="alternate-text" (click)="goToSignIn()">
                <p>&lt;</p>
                <div>Know your password? Sign in</div>
              </div>
            }

            <div class="need-help-text">
              Need help?
              <a class="tw-text-navy-blue" href="mailto:support@fylehq.com" target="_blank">Contact support</a>
            </div>
          </form>
        </div>
      </div>
    }

    @if (hasRequestedResetPasswordLink) {
      <div class="auth-card">
        <div class="content-container tw-text-center">
          <app-svg-sprite [icon]="'reset-email'" class="reset-email"></app-svg-sprite>
          <h3 class="check-email-text">Check your mail</h3>
          <div class="sent-mail">
            @if (!canChangeContent) {
              <p class="tw-m-0 tw-p-0 message-1">We have sent a reset link on your email address,</p>
            }
            @if (canChangeContent) {
              <p class="tw-m-0 tw-p-0 message-1">
                If there's a Fyle account linked to this email address, we'll send over instructions to reset your password.
              </p>
            }
            <p class="email">{{ this.resetPasswordForm.value }}</p>
          </div>
          @if (!canChangeContent) {
            <div class="link-text">
              <p class="tw-p-0 tw-m-0 message-2">Didn't receive the link?</p>
              @if (!isResendAPI) {
                <p-progressSpinner styleClass="spinner-14" strokeWidth="5" class="progress-spinner">
                </p-progressSpinner>
              }
              @if (isResendAPI) {
                <p class="resend-link" (click)="sendResetLink()">Resend link</p>
              }
            </div>
          }
        </div>
        <div class="or-text-container">
          <span class="or-text"> Or </span>
        </div>
        <ui-button
          [defaultType]="'submit'"
          [buttonType]="buttonType.PRIMARY"
          [customClasses]="'tw-font-700 tw-w-full tw-mt-spacing-4'"
          [label]="'Sign in'"
          (buttonClick)="goToSignIn()"
        >
        </ui-button>
      </div>
    }
  </div>

  <footer class="footer">Never lose track</footer>
</div>
