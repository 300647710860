<div class="auth-container tw-items-center tw-justify-center">

  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card tw-items-center">
      <h3 class="verify-title">Verifying identity</h3>
      <p class="description">Fetching your {{ targetConfig.BRAND_NAME }} account details...</p>

      <div class="spinner-container">
        <p-progressSpinner styleClass="spinner-50" strokeWidth="5"></p-progressSpinner>
      </div>
    </div>
  </div>
</div>
