import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, throwError } from 'rxjs';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { SignInErrorState } from '../sign-in/sign-in-error-state.enum';
import { SignInQueryParams } from '../sign-in/sign-in-query-params.interface';
import { GoogleSignInQueryParams } from './google-sign-in-query-params.interface';
import { TargetAppConfigService } from '../../core/services/target-app-config.service';
import { TargetAppConfig } from '../../core/models/target-app-config.model';
import { B64CodecService } from '@fyle/b64-codec';

@Component({
  selector: 'app-google-sign-in',
  templateUrl: './google-sign-in.component.html',
  styleUrls: ['./google-sign-in.component.scss'],
})
export class GoogleSignInComponent implements OnInit {
  showOverlay: boolean;

  header: string;

  signInErrorState: typeof SignInErrorState = SignInErrorState;

  errorState: SignInErrorState;

  alternateHeader: string;

  descriptions: string[];

  isNotAnExistingEmail: boolean;

  private queryParams: GoogleSignInQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private window: Window,
    private routerAuthService: RouterAuthService,
    private b64CodecService: B64CodecService,
    private targetAppConfigService: TargetAppConfigService,
  ) {}

  private handleError(errorResponse: HttpErrorResponse) {
    switch (errorResponse.status) {
      case 400:
        this.router.navigate(['pending_verification']);
        break;

      case 422:
        this.router.navigate(['disabled']);
        break;

      case 401:
        this.errorState = this.signInErrorState.UNAUTHORIZED;
        this.header = 'Unauthorised Access';
        this.descriptions = [
          `This account does not have authorised access to ${this.targetConfig.BRAND_NAME}. Please check your email once.`,
        ];
        this.alternateHeader = 'Wrong Account?';

        if (errorResponse.error) {
          this.isNotAnExistingEmail = true;
          this.header = 'Account Not Found';
          this.descriptions = [
            "We couldn't find any account with your email.",
            'Please check your email address again.',
          ];
          this.alternateHeader = `New to ${this.targetConfig.BRAND_NAME}?`;
        }
        break;

      case 406:
        this.router.navigate(['reset_password'], {
          queryParams: {
            tmp_pwd_expired: true,
          },
        });
        break;

      case 433:
        this.errorState = this.signInErrorState.ACCOUNT_LOCKED;
        this.header = 'Account Locked Temporarily';
        this.descriptions = [
          'This account has been locked temporarily, due to multiple failed login attempts. Please try again later.',
        ];
        this.alternateHeader = 'Wrong Account?';
        break;

      default:
        this.errorState = this.signInErrorState.UNKNOWN_ERROR;
        this.header = 'Something Went Wrong';
        this.descriptions = ['Please try again later.'];
        this.alternateHeader = 'Wrong Account?';
    }
  }

  private handleGoogleSignIn(state: SignInQueryParams) {
    this.routerAuthService
      .googleSignin(this.queryParams.access_token)
      .pipe(
        finalize(() => {
          this.showOverlay = false;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        }),
      )
      .subscribe(() => {
        const params = {
          asset: state.asset,
          fyle_redirect_url: state.fyle_redirect_url,
          org_id: state.org_id,
        };
        this.router.navigate(['verify'], { queryParams: params });
      });
  }

  openSignInPage() {
    this.router.navigate(['signin']);
  }

  openSignupDemoPage() {
    this.window.open('https://www.fylehq.com/schedule-demo');
  }

  ngOnInit(): void {
    this.header = '';
    this.descriptions = [];
    this.alternateHeader = '';
    this.showOverlay = true;
    this.queryParams = this.activatedRoute.snapshot.queryParams as GoogleSignInQueryParams;
    this.targetConfig = this.targetAppConfigService.getTargetConfig();

    /* When the permission denied to access google account */
    if (this.queryParams.error === 'access_denied') {
      this.router.navigate(['signin'], { queryParams: { error_code: this.queryParams.error } });
      return;
    }

    if (!this.queryParams.access_token) {
      const errorResponse: HttpErrorResponse = {
        status: 500,
        statusText: 'Google Signin Error',
      } as HttpErrorResponse;
      this.handleError(errorResponse);
      return;
    }

    const state = this.b64CodecService.decode<SignInQueryParams>(this.queryParams.state);
    this.handleGoogleSignIn(state);
  }
}
